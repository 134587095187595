/*--------------------------------------------------*/
/* Global Styles                                     */
/*--------------------------------------------------*/

body {
  overflow-x: hidden;
  font-family: "Prompt", sans-serif;
}

img {
  max-width: 100%;
}

.fa {
  font-family: FontAwesome;
}

a:hover {
  text-decoration: none;
}

.container {
  padding: 80px 0px;
}

h2 {
  margin-bottom: 40px;
}

.black {
  border-bottom: 1px solid black;
}

.white {
  border-bottom: 1px solid #fff;
}

/*--------------------------------------------------*/
/* Header and Top Banner Section                     */
/*--------------------------------------------------*/

.header {
  transition: all 0.3s ease-in-out;
  background: transparent;
  box-shadow: none;
}
.header.scrolled {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-nav .nav-link {
  transition: color 0.3s ease, transform 0.3s ease;
}
.navbar-nav .nav-link:hover {
  color: #007bff;
  transform: scale(1.1);
}

.nav-item.active .nav-link {
  font-weight: bold;
  color: #0056b3;
  border-bottom: 2px solid #0056b3;
}

.brand-icon {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}
.brand-icon:hover {
  transform: rotate(20deg);
}

.top-banner {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  padding: 40px 20px;
  margin-top: 56px;
}

.top-banner h1 {
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 10px;
}

.top-banner .lead {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cv-download-container {
  margin-top: 20px;
}

.cv-download-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: #007bff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  text-decoration: none;
  transition: background 0.3s ease, transform 0.3s ease;
}

.cv-download-button:hover {
  transform: translateY(-5px);
}

/*--------------------------------------------------*/
/* About Me Section                                  */
/*--------------------------------------------------*/

.about-me-section {
  background: linear-gradient(135deg, #f9f9f9, #e9ecef);
  padding: 40px 20px;
}

.about-me h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 40px;
}

.about-me-text {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.8;
}

.highlight {
  color: #2575fc;
}

/* Responsive Text */
@media (max-width: 768px) {
  .about-me h2 {
    font-size: 2rem;
  }

  .about-me-text {
    font-size: 1rem;
  }
}

/*--------------------------------------------------*/
/* Projects Section                                  */
/*--------------------------------------------------*/

#Projects {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: #fff;
}

.work_images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  text-align: center;
  max-width: 1200px;
}

.work_image {
  position: relative;
  margin: 0 10px 20px;
  width: 280px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.2)
  );
}

.work_image:hover {
  transform: scale(1.1) !important;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
}

.img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.work_image p {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
}

/* Modal Styles */
.modal-content-wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.modal-left {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.modal-right {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.btn-close-custom {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.btn-close-custom:hover {
  color: #d8d8d8;
}

.modal-content {
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
}

.project-modal .modal-title {
  font-size: 2rem;
  font-weight: bold;
}

.project-modal .project-description {
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.section-list {
  font-size: 0.9rem;
}

.project-description,
.project-features,
.languages-and-tech {
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.project-features ul,
.languages-and-tech ul {
  padding-left: 20px;
}

.project-features h5,
.languages-and-tech h5 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.github-link-wrapper {
  display: flex;
}

.github-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.github-link:hover,
.github-link:focus {
  transform: translateY(-3px);
  color: #fff;
  text-decoration: none;
}

.modal-icon {
  font-size: 1.5rem;
}

.image-container {
  text-align: center;
  margin-top: 20px;
}

.main-image {
  width: 100%;
  max-width: 400px;
  height: 220px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.additional-images {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.additional-images img {
  width: 100px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.additional-images img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .work_images {
    flex-direction: column;
  }

  .work_image {
    width: 95%;
    max-width: 100%;
  }

  .modal-content {
    padding: 20px;
  }

  .modal-title {
    font-size: 1.5rem;
  }

  .project-description,
  .project-features,
  .languages-and-tech {
    font-size: 1rem;
  }

  .additional-images {
    flex-direction: column;
    align-items: center;
  }

  .additional-images img {
    width: 80px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .work_images {
    flex-wrap: wrap;
  }

  .work_image {
    width: 45%;
    height: auto;
  }

  .modal-content {
    max-width: 700px;
    padding: 25px;
  }

  .project-features ul,
  .languages-and-tech ul {
    padding-left: 15px;
  }

  .main-image {
    max-height: 400px;
  }
}

/*--------------------------------------------------*/
/* Profile Section                                   */
/*--------------------------------------------------*/

/* Profile Section Styling */
.profile-section {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: #fff;
  padding: 40px 20px;
}

.profile {
  font-size: 2rem;
  margin-bottom: 30px;
}

.history {
  position: relative;
  margin: 0 auto;
  padding: 0 15px;
}

@media only screen and (min-width: 768px) {
  .history {
    width: 100%;
  }
}

.history:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #fff;
  position: absolute;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) {
  .history:before {
    left: 50%;
  }
}

.history .history__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 0 35px;
  animation: fadeInUp 0.5s ease-in-out;
}

@media only screen and (min-width: 768px) {
  .history .history__item {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 35px 20px;
  }

  .history .history__item:nth-of-type(2n) {
    justify-content: flex-end;
  }
}

.history .history__item:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
  left: 0;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) {
  .history .history__item:before {
    left: 50%;
    top: 0;
    width: 10px;
    height: 10px;
  }
}

.history .history__item:last-child {
  padding-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .history .history__item:last-child {
    padding-bottom: 0;
  }
}

.history .history__item:last-child:after {
  content: "";
  width: 6px;
  height: 6px;
  background: none;
  position: absolute;
  transform: translateX(-50%) rotate(-45deg);
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  bottom: 0;
  left: 0;
}

@media only screen and (min-width: 768px) {
  .history .history__item:last-child:after {
    left: 50%;
    width: 8px;
    height: 8px;
  }
}

.history .history__inner {
  max-width: 450px;
}

.history .history__image {
  margin: 20px 0;
}

.history .history__image img {
  width: 100%;
  max-width: 300px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.history .history__image img:hover {
  transform: scale(1.1);
}

.history .history__text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .history .history__text {
    margin-top: 15px;
  }
}

/*--------------------------------------------------*/
/* Skills Section                                    */
/*--------------------------------------------------*/

.skills-section {
  background-color: #f8f9fa;
  padding: 40px 20px;
}

.skill-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
}

.skill-category {
  flex: 1 1 45%;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: linear-gradient(135deg, #ffffff, #f1f1f1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.skill-category:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.skill-category h3 {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.skill-category h3 .black {
  font-weight: bold;
}

.skill-category ul {
  list-style: none;
  padding: 0;
}

.skill-category ul li {
  margin: 10px 0;
  font-size: 1.1rem;
  padding: 5px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.skill-category ul li:last-child {
  border-bottom: none;
}

.skill-category ul li:hover {
  background-color: #eaeaea;
  border-radius: 5px;
  padding-left: 15px;
}

.icon {
  margin-right: 10px;
  color: #333;
  vertical-align: middle;
  font-size: 1.5rem;
}

.skill-icon {
  margin-right: 10px;
  color: #007bff;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .skill-category {
    flex: 1 1 100%;
  }
}

/*--------------------------------------------------*/
/* Contact Section                                   */
/*--------------------------------------------------*/

/* Contact Section */
.contact-section {
  background: linear-gradient(135deg, #ffffff, #f7f7f7);
  padding: 40px 20px;
  text-align: center;
}

.contact-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.contact-description {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #555;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.submission-message {
  color: #28a745;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .contact-title {
    font-size: 2rem;
  }

  .contact-description {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .submit-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

/*--------------------------------------------------*/
/* Footer Styles                                     */
/*--------------------------------------------------*/

/* Footer Styling */
footer {
  padding: 30px 0;
  background-color: black;
  color: #fff;
  text-align: center;
}

.social-icons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.icon {
  font-size: 1.8rem;
  color: #fff;
  transition: color 0.3s ease;
}

.icon:hover,
.icon:focus {
  color: #ddd;
}
