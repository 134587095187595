.fade-in {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1s ease-in-out forwards;
}

.fade-in-delay {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

[data-aos] {
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1s;
}

[data-aos="fade-up"] {
  transform: translateY(20px);
}

[data-aos="zoom-in"] {
  transform: scale(0.9);
}

[data-aos].aos-animate {
  opacity: 1;
  transform: translateY(0) scale(1);
}
